<template>
  <div id="_homeview">
    <!-- <Carousel/> -->
    <HomeAccueil />
    <HomePage2 class="_i" />
    <HomePage3 class="_i" />
    <HomePage4 class="_i" />
    <HomePage5 class="_i" />
    <HomePage6 class="_i" />
    <!-- <EmptyPage /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import HomeAccueil from '@/components/home/HomeAccueil.vue'
import HomePage2 from '@/components/home/HomePage2.vue'
import HomePage3 from '@/components/home/HomePage3.vue'
import HomePage4 from '@/components/home/HomePage4.vue'
import HomePage5 from '@/components/home/HomePage5.vue'
import HomePage6 from '@/components/home/HomePage6.vue'
import FooterPage from '@/components/home/FooterPage.vue'
import Carousel from '@/components/home/Carousel.vue'
// import EmptyPage from '@/components/EmptyPage.vue'

export default {
  name: 'HomeView',
  metaInfo: {
    title: 'Accueil'
  },
  components: {
    HomeAccueil,
    HomePage2,
    HomePage3,
    HomePage4,
    HomePage5,
    HomePage6,
    FooterPage,
    Carousel,
    // EmptyPage,
  },
}
</script>

<style>
._i {
  margin-top: 50px;
}
</style>