<template>
    <div class="foo-main"></div>
</template>

<script>

export default {
    name: 'FooterPage',
}
</script>

<style scoped lang="less">
.foo-main {
    width: 100vw;
    height: 100vh;
    background-color: transparent;
}
</style>
  