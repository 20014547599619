import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeView from '../views/HomeView.vue'
import OfferView from '../views/OfferView.vue'
import RealisationView from '../views/RealisationView.vue'
import SocietyView from '../views/SocietyView.vue'
import NotFound from '../views/NotFound.vue'
import SelectedOffer from '../views/SelectedOffer.vue'
import ContactView from '../views/ContactView.vue'
import Legale from '../views/Legal.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/offers',
    name: 'offers',
    component: OfferView
  },
  {
    path: '/offer/:offer_type',
    name: 'offer',
    component: SelectedOffer
  },
  {
    path: '/realisations',
    name: 'realisations',
    component: RealisationView
  },
  {
    path: '/society',
    name: 'society',
    component: SocietyView
  },
  {
    path: '/contact',
    name: 'contact',
    // component: () => import('../views/ContactView.vue')
    component: ContactView
  },
  {
    path: '/404',
    name: 'notfound',
    component: NotFound
  },
  { path: '*', redirect: '/404' },
  {
    path: '/mentionlegales',
    name: 'mentionlegales',
    component: Legale
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
