<template>
    <div class="_responsive" id="bep4_ui_container">
        <div id="bep4_ui_h_line"></div>
        <div id="bep4_ui_v_line1"></div>
    </div>
</template>

<script>
export default {
    name: 'BeP4',
}
</script>

<style>
#bep4_ui_container {
    margin-top: 20px;
    width: 100%;
    position: absolute;
}

#bep4_ui_h_line {
    width: 100%;
    border-bottom: 1px solid black;
    position: absolute;
}

#bep4_ui_v_line1 {
    position: absolute;
    height: 130px;
    border-right: 1px solid black;
    left: 12%;
    top: -20px;
}

</style>