<template>
    <h1 id="_page_title">{{ title }}</h1>
</template>

<script>
export default {
    name: 'PageTitle',
    props: {
        title: String,
    }
}
</script>

<style>
#_page_title {
    font-size: 60px;
    color: #000000;
    padding: 20px 0;
    margin-top: 100px;
    font-weight: bold;
}
</style>