<template>
  <OfferPage />
</template>

<script>

import OfferPage from '@/components/offer/OfferPage.vue'

export default {
  name: 'OfferView',
  metaInfo: {
    title: 'Offers'
  },
  components: {
    OfferPage,
  },
}
</script>
