<template>
    <b-container>
        <b-row>
            <b-col>
                <BeP4 />
            </b-col>
        </b-row>
        <b-row style="margin-top: 70px;">
            <b-col xs="0" lg="5">
                <BeP42 />
            </b-col>
            <b-col xs="12" lg="7">
                <h2 id="steps-title">Donnez vie à vos envies en seulement quatre étapes.</h2>
                <b-row data-aos="fade-up" data-aos-delay="00" data-aos-duration="1000">
                    <b-col xs="6" lg="5">
                        <div class="steps-line-container">
                            <span>1.</span>
                            <div class="stepline"></div>
                        </div>
                        <h3 id="steps-sub-title">Prise de contact</h3>
                        <p>En premier lieu, nous échangerons sur votre projet ainsi que vos différentes envies afin
                            d’identifier vos besoins et de savoir comment nous pourrons y répondre. Un contact référent
                            vous sera attribué.</p>
                    </b-col>
                    <b-col xs="6" lg="5">
                        <div class="steps-line-container">
                            <span>2.</span>
                            <div class="stepline"></div>
                        </div>
                        <h3 id="steps-sub-title">Notre proposition</h3>
                        <p>Par la suite, nous vous transmettrons dans les plus brefs délais notre proposition avec
                            plusieurs options envisagées. Nous nous engageons avec toujours la même exigence, la même
                            énergie à répondre à vos attentes. Notre plus belle réussite est la satisfaction de nos
                            clients.</p>
                    </b-col>
                    <b-col xs="6" lg="5">
                        <div class="steps-line-container">
                            <span>3.</span>
                            <div class="stepline"></div>
                        </div>
                        <h3 id="steps-sub-title">Repérage technique</h3>
                        <p>Il est essentiel de venir faire un repérage pour valider la faisabilité de votre événement.
                            Cela nous permet également de vous rencontrer et de discuter en profondeur de votre projet
                            et de vos attentes. Durant ce rendez-vous nous répondons à vos demandes et anticipons les
                            imprévus.
                        </p>
                    </b-col>
                    <b-col xs="6" lg="5">
                        <div class="steps-line-container">
                            <span>4.</span>
                            <div class="stepline"></div>
                        </div>
                        <h3 id="steps-sub-title">Montage & suivi</h3>
                        <p>Dès lors que l’événement est terminé, nous établissons un bilan avec nos clients. L’objectif
                            de ce dernier est de nous améliorer sans cesse et d’avoir votre ressenti sur nos
                            prestations.
                        </p>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import BeP4 from '@/components/home/UI/BeP4.vue'
import BeP42 from '@/components/home/UI/BeP42.vue'

export default {
    name: 'HomePage4',
    components: {
        BeP4,
        BeP42,
    },
}
</script>

<style scoped lang="less">
html {
    font-size: 100%;
}

grid-gutter-width {
    padding: 0 !important;
}

p {
    font-size: 15px;
}

#steps-title {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: -1px;
}

#steps-sub-title {
    font-weight: bold;
    font-size: 20px;
}

.steps-line-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: white;
}

.stepline {
    border-bottom: 1px solid black;
    width: 100%;
    margin-bottom: 7px;
    margin-left: 5px;
}
</style>
  