<template>
    <div class="_responsive">
        <div id="hvertical-line-container">
            <div id="hv_line1"></div>
            <div id="hv_line2"></div>
            <div id="hv_line3"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BeP1',
}
</script>

<style>

#hvertical-line-container {
    z-index: 5;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
}

#hv_line1 {
    height: 50px;
    border-left: 1px solid white;
    position: absolute;
    left: 70%;
    bottom: 0;
}

#hv_line2 {
    height: 225px;
    border-left: 1px solid white;
    position: absolute;
}

#hv_line3 {
    height: 100px;
    border-left: 1px solid white;
    position: absolute;
    left: 30%;
}
</style>