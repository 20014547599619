<template>
    <div>
        <b-container>
            <p>Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l&#39;économie
                numérique, il est précisé aux utilisateurs du site Back Events l&#39;identité des différents
                intervenants dans le
                cadre de sa réalisation et de son suivi.</p><br>
            <h4>Edition du site</h4><br>
            <p>Le présent site, accessible à l’URL www.back-events.fr (le « Site »), est édité par :</p><br>
            <p>BACK, société au capital de 5000 euros, inscrite au R.C.S. de NANTERRE sous le numéro RCS Nanterre
                498 039 999, dont le siège social est situé au 27 rue des vallées 92290 Châtenay-Malabry, représenté(e)
                par
                Brigitte PERIER dûment habilité(e)</p><br>
            <p>Le numéro individuel TVA de l’éditeur est : FR06498039999.</p><br>
            <h4>Hébergement</h4><br>
            <p>Le Site est hébergé par la société OVH SAS, situé 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex
                (contact téléphonique ou email : 1007).</p><br>
            <h4>Directeur de publication</h4><br>
            <p>Le Directeur de la publication du Site est Brigitte PERIER .</p><br>
            <h4>Nous contacter</h4><br>
            <p>Par téléphone : +33687044582</p><br>
            <p>Par email : contact@back-events.fr</p><br>
            <p>Par courrier : 27 rue des vallées 92290 Châtenay-Malabry</p><br>
        </b-container>
    </div>
</template>
  
<script>
// @ is an alias to /src


export default {
    name: 'Legal',
    metaInfo: {
        title: 'Mention Légales',
        titleTemplate: '%s - Back-Events',
    },
    created() {
        window.scrollTo(0, 0);
    },
}
</script>