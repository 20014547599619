<template>
    <div class="_responsive">
        <div id="bep4-2_ui_v_container">
            <div id="bep4-2_ui_v_line"></div>
            <div id="bep4-2_ui_v_line1"></div>
            <div id="bep4-2_ui_v_line2"></div>
        </div>
        <span id="bep4-2_ui_n01" data-aos="fade-right" data-aos-delay="0" data-aos-duration="1000">01</span>
        <div id="_bep4-2_ui_square"></div>
        <span id="bep4-2_ui_n02" data-aos="fade-left" data-aos-delay="0" data-aos-duration="1000">04</span>
        <div id="bep4-2_ui_h_container">
            <div id="bep4-2_ui_h_line"></div>
            <div id="bep4-2_ui_h_line1"></div>
            <div id="bep4-2_ui_h_line2"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BeP42',
}
</script>

<style>
#_bep4-2_ui_square {
    position: absolute;
    height: 240px;
    width: 40px;
    left: 45%;
    top: 20%;
    background-color: #90D0E1;
    z-index: 2;
}

#bep4-2_ui_n01 {
    position: absolute;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    top: 2%;
    left: 7%;
    font-size: 110px;
}

#bep4-2_ui_n02 {
    position: absolute;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    bottom: 0%;
    right: 24%;
    font-size: 110px;
}

#bep4-2_ui_v_container {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    position: absolute;
}

#bep4-2_ui_v_line {
    height: 230px;
    border-right: 1px solid black;
    bottom: 0;
    left: 30%;
    position: absolute;
}

#bep4-2_ui_v_line1 {
    position: absolute;
    height: 70%;
    border-right: 1px solid black;
    left: 47%;
    top: 0;
}

#bep4-2_ui_v_line2 {
    position: absolute;
    height: 280px;
    border-right: 1px solid black;
    left: 70%;
    bottom: 0;
}

/* ## */

#bep4-2_ui_h_container {
    width: 100%;
    height: 100%;
    position: absolute;
}

#bep4-2_ui_h_line {
    width: 60%;
    border-bottom: 1px solid black;
    position: absolute;
    top: 30%;
}

#bep4-2_ui_h_line1 {
    position: absolute;
    width: 80%;
    border-bottom: 1px solid black;
    top: 70%;
}

#bep4-2_ui_h_line2 {
    position: absolute;
    width: 130px;
    border-bottom: 1px solid black;
    bottom: 0;
    right: 30%;
}
</style>