<template>
  <ContactPage />
</template>
  
<script>
// @ is an alias to /src
import ContactPage from '@/components/contact/ContactPage.vue'

export default {
  name: 'ContactView',
  components: {
    ContactPage,
  },
}
</script>