<template>
    <div class="_responsive">
        <div id="scertical-line-container">
            <div id="sc2_line1"></div>
            <div id="sc2_line2"></div>
            <div id="sc2_line3"></div>
            <div id="sc2_line4"></div>
            <span id="sc2_span">L'architecte de vos événements.</span>
            <div id="sc2_square"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'beS2',
}
</script>

<style>
#scertical-line-container {
    z-index: 5;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
}

#sc2_line1 {
    height: 1100px;
    border-left: 2px solid rgb(0, 0, 0);
    position: absolute;
    top: 10%;
    left: 25%;
    z-index: 3;
}

#sc2_line2 {
    width: 350px;
    border-bottom: 2px solid rgb(0, 0, 0);
    position: absolute;
    top: 41%;
    left: 0;
    right: 0;
}

#sc2_line3 {
    height: 330px;
    border-left: 2px solid rgb(0, 0, 0);
    position: absolute;
    top: 17%;
    left: 45%;
}
#sc2_line4 {
    position: absolute;
    width: 260px;
    border-bottom: 2px solid black;
    left: 19%;
    top: 65%;
    z-index: 3;
}
#sc2_span {
    margin-left: 100px;
    font-style: italic;
    position: absolute;
    top: 62%;
}
#sc2_square {
    position: absolute;
    height: 300px;
    width: 50px;
    left: 20%;
    top: 50%;
    z-index: 1;

    background-color: #90D0E1;
}
</style>