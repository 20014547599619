<template>
    <div class="bcmain ajust">
        <div class="bottom-section-container">
            <div class="be-bigC-left">
                <span id="title">{{ title }}</span>
            </div>
            <div class="be-bigC-right">
                <BeButton content="découvrir" url="offers"/>
            </div>
        </div>

    </div>
</template>

<script>
import BeButton from '@/components/BeButton.vue'

export default {
    name: 'HomeBigCard',
    components: {
        BeButton,
    },
    props: {
        filepath: String,
        title: String,
    }
}
</script>

<style scoped lang="less">

@media (max-width: 995px) {
    .ajust {
        height: 50vh;
    }
}
@media (min-width: 995px) {
    .ajust {
        height: 100%;
    }
}
.bcmain {
    background-image: url('@/assets/img/TentesNomades/tente_strecth7.jpg');
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px;
}

#title {
    color: white;
    font-weight: normal;
    font-size: 20px;
}

.bottom-section-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 30px;
}

.be-bigC-left {
    color: white;
}

.be-bigC-right {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
}

#discover-btn-title {
    margin-right: 20px;
    text-transform: uppercase;
}



.cardCarousel {
    width: 100%;
    height: 100%;
}

.slide1 {

    background-image: url('@/assets/home/1.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slide2 {

    background-image: url('@/assets/home/3.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slide3 {

    background-image: url('@/assets/home/4.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
