<template>
  <SocietyPage />
</template>

<script>
// @ is an alias to /src
import SocietyPage from '@/components/society/SocietyPage.vue'

export default {
  name: 'SocietyView',
  metaInfo: {
    title: 'Notre Société'
  },
  components: {
    SocietyPage,
  },
}
</script>
