<template>
    <b-container style="padding-bottom: 200px;">
        <b-row>
            <b-col xs="12" lg="3">
                <h1 style="font-weight: bold;">Back Events,</h1>
            </b-col>
            <b-col class="_responsive" xs="0" lg="9">
                <BeP6 />
            </b-col>
        </b-row>
        <h2 id="desire-title">plus qu'un métier, une passion.</h2>
        <b-row data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
            <b-col cols="6">
                <div class="desire-line-container">
                    <span>1.</span>
                    <div class="desireline"></div>
                </div>
                <h3 id="desire-sub-title">Expertise</h3>
                <p class="hp6-p">Notre équipe est à votre écoute pour répondre à toutes vos attentes en choisissant la
                    solution la plus adaptée afin de sublimer votre événement.
                </p>
            </b-col>
            <b-col cols="6">
                <div class="desire-line-container">
                    <span>2.</span>
                    <div class="desireline"></div>
                </div>
                <h3 id="desire-sub-title">Accompagnement</h3>
                <p class="hp6-p">Dès la prise en charge du projet, nous vous accompagnons tout au long de l’événement
                    pour que celui-ci soit une réussite.</p>
            </b-col>
            <b-col cols="6">
                <div class="desire-line-container">
                    <span>3.</span>
                    <div class="desireline"></div>
                </div>
                <h3 id="desire-sub-title">Savoir-faire</h3>
                <p class="hp6-p">Notre connaissance de ce domaine ainsi que notre équipe aussi performante que
                    professionnelle auront soin de réaliser au mieux votre projet.</p>
            </b-col>
            <b-col cols="6">
                <div class="desire-line-container">
                    <span>4.</span>
                    <div class="desireline"></div>
                </div>
                <h3 id="desire-sub-title">Réactivité</h3>
                <p class="hp6-p">Nos équipes sont disponibles à tout moment. Un gain de temps et d’efficacité pour un
                    événement en toute sérénité.</p>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

import LastrCard from '@/components/home/cards/LastrCard.vue'
import BeP6 from '@/components/home/UI/BeP6.vue'

export default {
    name: 'HomePage6',
    components: {
        LastrCard,
        BeP6,
    }
}
</script>

<style scoped lang="less">
#desire-title {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: -1px;
    margin-bottom: 30px;
}

#desire-sub-title {
    font-weight: bold;
    font-size: 20px;
}

.desire-line-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: white;
}

.desireline {
    border-bottom: 1px solid black;
    width: 100%;
    margin-bottom: 7px;
    margin-left: 5px;
}

.hp6-p {
    text-align: justify;
}
</style>
  