<template>
  <div id="app">
    <BeNavbar />
    <router-view />
    <BeFooter />
  </div>
</template>

<script>
import BeNavbar from '@/components/BeNavbar.vue'
import BeFooter from '@/components/BeFooter.vue'

export default {
  components: {
    BeNavbar,
    BeFooter,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  metaInfo: {
    title: 'Back-events',
    titleTemplate: '%s',
    htmlAttrs: {
      lang: 'fr-FR'
    },
    meta: [
      { property: 'og:title', content: 'Back Events' },
      { property: 'og:site_name', content: 'Back Events' },
      { name: 'description', content: 'Back Events, l\'architecte de vos événements.' },
      { property: 'og:description', content: 'Back Events, l\'architecte de vos événements.' },

      // Google:
      { itemprop: 'name', content: 'Back Events' },
      { itemprop: 'description', content: 'L\'architecte de vos événements.' },
      // { itemprop: 'image', content: 'https://www.mon-site.com/ma-page-special.jpg' }
    ]
  },
}

</script>

<style lang="less">

#app {
  font-family: Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1f1f1f;
  margin: 0;
  background-color: #ffffff;
}

@media (max-width: 995px) {
  ._responsive {
    display: none;
    width: 100vw;
    margin: 0 !important;
  }
}
</style>
