<template>
    <div class="main">
        <b-container>
            <PageTitle title="Réalisations" />
            <b-row>
                <p style="font-style: italic;">Bientôt disponnible...</p>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue'

export default {
    name: 'RealisationPage',
    components: {
        PageTitle,
    },
    created() {
        window.scrollTo(0, 0);
    },
}
</script>

<style scoped lang="less">
.main {
    height: 100vh;
    width: 100vw;
}
</style>
  