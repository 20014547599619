<template>
    <div>
        <b-carousel class="be-carousel-2" id="carousel-1" :interval="3000">
            <b-carousel-slide class="cslide1"></b-carousel-slide>
            <b-carousel-slide class="cslide2"></b-carousel-slide>
            <b-carousel-slide class="cslide3"></b-carousel-slide>
            <b-carousel-slide class="cslide4"></b-carousel-slide>
            <b-carousel-slide class="cslide5"></b-carousel-slide>
            <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
        </b-carousel>
        <BeButton style="margin: 20px 0;" :content="btn_content" :url="btn_url" />
    </div>
</template>
  
<script>
import BeButton from '@/components/BeButton.vue'

export default {
    name: 'CarouselCard',
    components: {
        BeButton,
    },
    props: {
        btn_content: String,
        btn_url: String,
    },
}
</script>

<style>
.be-carousel-2 {
    height: 100%;
    overflow: hidden;
}

.cslide1 {
    height: 50vh;
    width: 100%;
    background-image: url('@/assets/img/mobilier/P8290498.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cslide2 {
    height: 50vh;
    width: 100%;
    background-image: url('@/assets/home/6.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cslide3 {
    height: 50vh;
    width: 100%;
    background-image: url('@/assets/home/3.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cslide4 {
    height: 50vh;
    width: 100%;
    background-image: url('@/assets/home/4.jpg');
    background-color: #eeeeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cslide5 {
    height: 50vh;
    width: 100%;
    background-image: url('@/assets/home/5.jpg');
    background-color: #070707;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>