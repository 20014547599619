<template>
    <div class="_responsive" id="bep3_ui_container">
        <div id="bep3_ui_h_line"></div>
        <div id="bep3_ui_v_line1"></div>
        <div id="bep3_ui_v_line2"></div>
    </div>
</template>

<script>
export default {
    name: 'BeP3',
}
</script>

<style>
#bep3_ui_container {
    width: 100%;
    position: absolute;
}

#bep3_ui_h_line {
    width: 100%;
    border-bottom: 1px solid black;
    position: absolute;
}

#bep3_ui_v_line1 {
    position: absolute;
    height: 180px;
    border-right: 1px solid black;
    right: 12%;
    top: -180px;
    z-index: 2;
}

#bep3_ui_v_line2 {
    position: absolute;
    height: 200px;
    border-right: 1px solid black;
    left: 12%;
    top: -40px;
}
</style>