<template>
    <div class="_responsive" id="bep6_ui_container">
        <div id="bep6_ui_h_line"></div>
        <div id="bep6_ui_square"></div>
        <div id="bep6_ui_v_line2"></div>
    </div>
</template>

<script>
export default {
    name: 'BeP6',
}
</script>

<style>
#bep6_ui_container {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#bep6_ui_h_line {
    width: 100%;
    border-bottom: 1px solid black;
    position: absolute;
    z-index: 2;
    bottom: 50%;
}

#bep6_ui_square {
    position: absolute;
    width: 350px;
    height: 40px;
    background-color: #90D0E1;
    left: 50%;
}

#bep6_ui_v_line2 {
    position: absolute;
    height: 70%;
    border-right: 1px solid black;
    left: 20%;
}

</style>