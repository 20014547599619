<template>
    <div class="_responsive">
        <div id="scertical-line-container">
            <div id="sc_line1"></div>
            <div id="sc_line2"></div>
            <div id="sc_line3"></div>
            <span id="sc_span">Une signature unique.</span>
            <div id="sc_square"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'beS1',
}
</script>

<style>
#scertical-line-container {
    z-index: 5;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
}

#sc_line1 {
    height: 300px;
    border-left: 2px solid rgb(0, 0, 0);
    position: absolute;
    top: 30%;
    left: 45%;
}

#sc_line2 {
    width: 350px;
    border-bottom: 2px solid rgb(0, 0, 0);
    position: absolute;
    right: 0;
}

#sc_line3 {
    height: 270px;
    border-left: 2px solid rgb(0, 0, 0);
    position: absolute;
    top: 30%;
    left: 85%;
    z-index: 3;
}
#sc_span {
    margin-left: 130px;
    font-style: italic;
}
#sc_square {
    position: absolute;
    height: 100px;
    width: 50px;
    left: 80%;
    top: 20%;
    z-index: 1;

    background-color: #90D0E1;
}
</style>