<template>
    <b-container fluid>
        <b-row>
            <b-col xs="12" lg="3"></b-col>
            <b-col id="s_off_container_p1_2" xs="12" lg="6">
                <div>
                    <h1 data-aos="fade-up" data-aos-delay="00" data-aos-duration="2000">{{ title }}
                    </h1>
                    <p data-aos="fade-up" data-aos-delay="1250" data-aos-duration="1000">{{ description }}</p>
                    <div id="offer-back-btn-container">
                        <div data-aos="fade" data-aos-delay="3000" id="offer-back-btn" @click="goBack">Retour</div>
                    </div>
                </div>
            </b-col>
            <b-col xs="12" lg="3"></b-col>
            <b-row>
                <b-col v-for="item in this.mosaicList" style="overflow: scroll; margin-bottom: 20px;" lg="4">
                    <img style="max-height: fit-content; width: 100%; " :src="item" alt="">
                </b-col>
            </b-row>
        </b-row>
    </b-container>
</template>

<script>
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage'

const storage = getStorage()

export default {
    name: 'SelectedOffer',
    metaInfo: {
        title: 'Offer'
    },
    data() {
        return {
            main_pic: String,
            title: String,
            description: String,
            mosaicList: []
        }
    },
    created() {
        window.scrollTo(0, 0);
        switch (this.$route.params.offer_type) {
            case "crystal":
                this.getMosaic('cristal')
                this.title = "L’élégance du cristal."
                this.description = "La tente cristal permet à la structure de se fondre dans le paysage et d’être en harmonie avec l’environnement extérieur. En journée, la tente laisse entrer la lumière naturelle et permet aux convives de profiter du cadre de votre événement. La nuit, les éclairages vous plongerons dans une atmosphère magique. Le toit peut être en cristal simple ou tendu à chaud selon vos préférences. Elle est constituée de plusieurs points d’ancrage et peut être fixée de différentes manières: piquetage dans le sol ou auto lestée. Elle résiste à près de 100km/h de vent."
                break;
            case "velum":
                this.getMosaic('velum')
                this.title = "Plus qu’un simple classique."
                this.description = "La tente vélum est une tente de réception classique dont les parois intérieures du toit sont habillées d’un tissu en coton gratté sur mesure.Le tissu peut être plissé ou tendu. Il apporte chic, élégance et cocooning à votre réception ou événement.. Il a comme propriété d’insonoriser l’espace, et permet dans les périodes d’hiver de garder la chaleur."
                break;
            case "nomade":
                this.getMosaic('nomade')
                this.title = "Une nouvelle façon de recevoir."
                this.description = "Cette structure de réception élégante, flexible et légère s’adapte à tous types d’environnement. Esthétiques et innovantes la tente stretch s’intègre, grâce à son élégance naturelle, aux lieux les plus variés, sans les dénaturer. Leurs courbes apporteront du prestige à votre événement. La toile que nous proposons est extrêmement robuste, 100% étanche et conforme aux normes de sécurité incendie. La tente stretch est un produit haut de gamme et élégant qui conviendra à de nombreuses occasions: réceptions privées, mariages, cocktail, cérémonies laîques, manifestations sportives... Les tentes stretch sont une véritable invitation au voyage et au dépaysement."
                break;
            case "legere":
                this.getMosaic('legere')
                this.title = "Harmonisez vos espaces."
                this.description = "Ces tentes possèdent des attributs particuliers et facilitent l’aménagement sur des espaces atypiques : terrasses, jardins ou encore les rooftops et les passages. Ces tentes vous laissent de nombreux choix concernant leur composition vous permettant d’harmoniser vos couleurs (PVC blanc, bicolore ou encore totalement cristal)."
                break;
            case "mobilier":
                this.getMosaic('mobilier')
                this.title = "Aménagez et décorez."
                this.description = "De toutes ses formes et de toutes couleurs, notre mobilier s’harmonisera à chacun de vos thèmes ou événements/réceptions."
                break;
            case "amenagement":
                this.getMosaic('amenagement')
                this.title = "SUBLIMEZ VOTRE ESPACE."
                this.description = "Nous faisons de votre événement un moment unique et féérique."
                break;
            case "sanitaire":
                this.getMosaic('sanitaire')
                this.title = "Sanitaires."
                this.description = "Indispensable pour vos hôtes, de grande qualité esthétique et de nouvelle génération."
                break;
            case "sol":
                this.getMosaic('sol')
                this.title = "L’indispensable."
                this.description = "De différentes sortes, plancher sur châssis soit nature ou recouvert de moquette pour vos salons ou expositions. Le parquet vitrifié faisant office de piste de danse sera le complément indispensable à vos réceptions haut de gamme."
                break;
            case "eclairageson":
                this.getMosaic('eclairageson')
                this.title = "Accordez vos sens."
                this.description = "Un événement des plus féérique grâce à l’association complémentaire et indispensable du son et de la lumière. Réel « must have » de vos événements, l’harmonisation parfaite de ces deux composants sublimera votre soirée par la prestation de votre orchestre ou encore votre disc-jockey. L’ensemble de ces éléments sont facilement aménageables à l’ensemble de nos locations de tentes."
                break;
            case "structure":
                this.getMosaic('structure')
                this.title = "Structures."
                this.description = "Espaces de réception classique Bi-pente ou arquée, ces structures sont modulables pour tous types d’événements pincées au sol ou lestées."
                break;
            case "chauffage":
                this.getMosaic('chauffage')
                this.title = "Un complément indispensable pour votre confort"
                this.description = "De 25 kW à +400 kWa. Fuel ou électrique, petit ou grand volume, il est l’accessoire indispensable pour le confort de vos événements."
                break;
            default:
                break;
        }
    },
    methods: {
        goBack() {
            window.history.back()
        },
        getMosaic(type) {
            const listRef = ref(storage, type)
            listAll(listRef).then((res) => {
                res.items.forEach((itemRef) => {
                    this.pushUrl(itemRef)
                });
            }).catch((error) => {
                console.log(error)
            })
        },
        async pushUrl(ref) {
            var url = await getDownloadURL(ref)
            this.mosaicList.push(url)
        }
    },

}

</script>

<style lang="less">
#s_off_container_p1_1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

#s_off_container_p1_2 {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#s_off_main_img {
    max-width: 100%;
    // max-height: 100vh;
    width: 100%;
    display: block;
    object-fit: cover;
}

#offer-back-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
}

#offer-back-btn {
    cursor: pointer;
    font-size: large;
    text-transform: uppercase;

    &:hover {
        text-decoration: underline;
    }
}
</style>